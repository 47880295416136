import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function CookiesPolicy() {
  return (
    <Container 
      component="main" 
      maxWidth="md" 
      sx={{ 
        mt: 10,
        py: { xs: 2, sm: 4, md: 6 },
        px: { xs: 1, sm: 2, md: 3 },
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center'
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom 
          sx={{ fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' } }}
        >
          Cookie Policy pre navzdy.online
        </Typography>
        <Typography variant="body1" paragraph>
          Táto Cookie Policy vysvetľuje, čo sú cookies a ako ich používame na webovej stránke navzdy.online. Používaním tejto stránky vyjadrujete svoj súhlas s používaním cookies v súlade s touto politikou.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Čo sú cookies?
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies sú malé textové súbory, ktoré sa ukladajú vo vašom zariadení (počítači, tablete alebo smartfóne) pri návšteve webovej stránky. Slúžia na zlepšenie vašich skúseností na webe, umožňujú zapamätať si vaše preferencie a pomáhajú nám analyzovať návštevnosť.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Aké typy cookies používame?
        </Typography>
        <Typography variant="body1" paragraph>
          Na našej stránke môžeme používať nasledujúce typy cookies:
        </Typography>
        <Box component="ul" sx={{ pl: 2 }}>
          <li>
            <Typography variant="body1">
              <strong>Nezbytné cookies:</strong> Tieto cookies sú potrebné na to, aby web fungoval správne a umožňovali základné funkcie, ako je bezpečné prihlasovanie a navigácia.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Analytické cookies:</strong> Pomáhajú nám pochopiť, ako návštevníci používajú našu stránku, čo nám umožňuje zlepšiť jej obsah a funkčnosť.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Funkčné cookies:</strong> Umožňujú zapamätať si vaše voľby a preferencie (napr. jazyk, región) a poskytujú vám vylepšené funkcie.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Cieľové alebo reklamné cookies:</strong> Tieto cookies sa používajú na zobrazovanie relevantných reklám a zlepšenie reklamných kampaní.
            </Typography>
          </li>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom>
          Ako môžete cookies kontrolovať alebo zablokovať?
        </Typography>
        <Typography variant="body1" paragraph>
          Väčšina webových prehliadačov umožňuje spravovať nastavenia cookies. Môžete nastaviť prehliadač tak, aby cookies odmietol alebo aby vás upozornil pri ich odosielaní. Upozorňujeme však, že zablokovanie cookies môže ovplyvniť funkčnosť tejto stránky.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Zmeny v Cookie Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Túto Cookie Policy môžeme čas od času aktualizovať, aby odrážala zmeny v našich praktikách alebo z právnych dôvodov. Odporúčame vám pravidelne kontrolovať túto stránku, aby ste boli informovaní o aktuálnej politike.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Kontakt
        </Typography>
        <Typography variant="body1" paragraph>
          Ak máte akékoľvek otázky alebo pripomienky týkajúce sa našej Cookie Policy, neváhajte nás kontaktovať na adrese: <a href="mailto:info@navzdy.online" style={{ color: 'inherit', textDecoration: 'underline' }}>info@navzdy.online</a>.
        </Typography>
      </Box>
    </Container>
  );
}

export default CookiesPolicy;
