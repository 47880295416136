import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import instance from '../../helpers';
import { List, ListItem,ListItemIcon } from '@mui/material';
import People from '@mui/icons-material/People';
import { useTranslation } from 'react-i18next';

const fetchDataCategories = async () => {
  const response = await instance().get(`/products`);
  return response.data;
};

export default function Service() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { data, status } = useQuery([], fetchDataCategories, { cacheTime: 0 });

  const detailArr = [
    ['order11','order12','order13','order14'],
    ['order21','order22','order23','order24','order25'],
    ['order31','order32','order33','order34','order35','order36']
  ]
  return (
    <Box sx={{ flexGrow: 1, p: { xs: 2, md: 4 } }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} textAlign="center">
          <Typography variant="h3">{t('myService')}</Typography>
        </Grid>

        {data && data.data && data.data.slice(0, 3).reverse().map((item, index) => (
          <Grid key={index} item xs={12} sm={10} lg={3} md={4} sx={{ mx: { xs: 0, md: 1.5 }, my: { xs: 2, md: 0 }, display: 'flex' }}>
          <Box className="service-background2" 
               sx={{ 
                flex: 1, 
                padding: 2, 
                borderRadius: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                border: `2px solid ${index !== 1 ? '#B3D5E7' : 'white'}`,
                background: index === 1 ? '#B3D5E7' : 'white'
              }}
            >
              <Typography variant="h4" sx={{ fontSize: 25, textAlign: 'center',textTransform:'uppercase',margin:'25px'}}>
                {item.title || ''} <span style={{fontWeight:'bold'}}>{item.age || ''} {t('age')}</span>
              </Typography>

        
              <Box sx={{ mt: 2, mb: 2 }}>
                <ul style={{ textAlign: 'left', paddingLeft: 50, margin: '0 auto', maxWidth: 'fit-content' }}>
                  { detailArr[index].map( e => <li>{t(e)}</li>) }
                 
                </ul>
             </Box>

             {/* <Typography variant="h4" sx={{ fontSize: 25, textAlign: 'center',textTransform:'uppercase',margin:'10px' }}>
             {t('fullPrice')} {item.price+50 || ''}€
              </Typography> */}

              <Typography variant="h4" sx={{ fontSize: 25, textAlign: 'center',textTransform:'uppercase',fontWeight:'bold' }}>
              {t('price')} {item.price || ''}€
              </Typography>

            
              <Grid container justifyContent="center" my={2}>
                <Button
                  className="buttonSlider"
                  onClick={() => navigate(`/order/${item._id || '1'}`)}
                  variant="outlined"
                  size="large"
                  sx={{  }}
                >
                  {t('order')}
                </Button>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
