// import React from 'react';
// import { Container, Typography, Box } from '@mui/material';

// function Vop() {
//   return (
//     <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
//       <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//         <Typography variant="h3" component="h1" gutterBottom>
//            VOP
//         </Typography>
//         <Typography variant="body1">
//         Všeobecné obchodné podmienky
//         </Typography>
//       </Box>
//     </Container>
//   );
// }

// export default Vop;
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function TermsAndConditions() {
  return (
    <Container 
      component="main" 
      maxWidth="md" 
      sx={{ 
        mt: 10,
        py: { xs: 2, sm: 4, md: 6 },
        px: { xs: 1, sm: 2, md: 3 },
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center'
      }}
    >
      <Box sx={{ width: '100%', mb: 4 }}>
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom 
          sx={{ fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' } }}
        >
          Obchodné podmienky pre Navždy.online s. r. o.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          1. Všeobecné ustanovenia
        </Typography>
        <Typography variant="body1" paragraph>
          Tieto obchodné podmienky (ďalej len „Podmienky“) upravujú právne vzťahy medzi spoločnosťou Navždy.online s. r. o., Pezinská 1791/1, 900 81 Šenkvice, IČO: 56589786, DIČ: 2122353838 (ďalej len „Predávajúci“), a zákazníkmi (ďalej len „Kupujúci“), ktorí nakupujú produkty a služby prostredníctvom internetovej stránky www.navzdy.online.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          2. Predmet podnikania
        </Typography>
        <Typography variant="body1" paragraph>
          Predávajúci poskytuje služby a produkty spojené s výrobou a predajom QR plakiet, ktoré umožňujú Kupujúcim nahrávať a zobrazovať fotografie a videá zosnulých osôb.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          3. Objednávka a uzatvorenie zmluvy
        </Typography>
        <Typography variant="body1" paragraph>
          Objednávku je možné uskutočniť prostredníctvom online formulára na webovej stránke. Zmluva medzi Predávajúcim a Kupujúcim sa považuje za uzatvorenú od momentu potvrdenia objednávky zo strany Predávajúceho. Kupujúci je povinný uviesť pri objednávke pravdivé a kompletné údaje.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          4. Cena a platobné podmienky
        </Typography>
        <Typography variant="body1" paragraph>
          Ceny produktov sú uvedené v eurách vrátane DPH. Platbu je možné vykonať online prostredníctvom platobnej karty alebo bankovým prevodom.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          5. Dodanie produktov
        </Typography>
        <Typography variant="body1" paragraph>
          Produkt bude dodaný spôsobom uvedeným pri objednávke. Predávajúci neručí za omeškanie spôsobené na strane doručovateľa.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          6. Reklamačný poriadok a odstúpenie od zmluvy
        </Typography>
        <Typography variant="body1" paragraph>
          Kupujúci má právo reklamovať chybný produkt do 14 dní od jeho prevzatia. Reklamácie je potrebné zaslať na e-mail <a href="mailto:info@navzdy.online" style={{ color: 'inherit', textDecoration: 'underline' }}>info@navzdy.online</a>. V prípade odstúpenia od zmluvy má Predávajúci právo účtovať nevyhnutné náklady spojené s výrobou produktu.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          7. Ochrana osobných údajov
        </Typography>
        <Typography variant="body1" paragraph>
          Všetky informácie o ochrane osobných údajov nájdete v sekcii „Zásady ochrany osobných údajov“.
        </Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          8. Záverečné ustanovenia
        </Typography>
        <Typography variant="body1" paragraph>
          Tieto obchodné podmienky nadobúdajú účinnosť dňom ich zverejnenia. Predávajúci si vyhradzuje právo kedykoľvek upraviť tieto Podmienky.
        </Typography>
      </Box>
    </Container>
  );
}

export default TermsAndConditions;
