// import logo from './logo.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.css';
import SimpleSlider from './components/Slider';
import Info from './components/Info';
import Grid from '@mui/material/Unstable_Grid2';
import Service from './components/Service';
import Slider2 from './components/Slider2';
import Info2 from './components/Info2';
import Divider from '@mui/material/Divider';
import { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom";
// import LoadingIndicator from "../../../react/src/components/LoadingIndicator";

// Import ikon z @mui/icons-material
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

import CookieConsent from "react-cookie-consent";


function Home() {

 const { isAuthenticated, isLoading, verifyToken } = useContext(AuthContext);
 useEffect(() =>{ verifyToken()},[]);

 if (isAuthenticated) {
   return <Navigate to="/profile" replace />;
 }

  return (
    <div >
        <Grid container md={12}>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: '100%', width: '100%',marginTop:64 }}>
          <iframe

            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            src="https://www.youtube.com/embed/cbW_G_BGd3s?autoplay=1&mute=1&vq=hd1080&loop=1&playlist=cbW_G_BGd3s"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Grid>
    <SimpleSlider />
     <Info />
     <Slider2 />
  
     <Service />
   
    <Info2 />
    <Grid container  md={12} sm={12}>
      <Grid mdOffset={0.5} smOffset={0.5} md={11} sm={11} className="block-divider">
        <Divider className='bold-divider' />
      </Grid>
    </Grid>

     {/* Nový riadok s odkazom VOP, Facebook a Instagram */}
     <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: '20px' }}>
        <Grid item xs={4} sm={4} md={4} style={{ textAlign: 'center' }}>
          <a href="/vop" className="footer-link">
            VOP
          </a>
        </Grid>
        <Grid item xs={4} sm={4} md={4} style={{ textAlign: 'center' }}>
          <a href="https://www.facebook.com/profile.php?id=61561792603205" target="_blank" rel="noopener noreferrer" className="footer-link">
            <FacebookIcon fontSize="large" />
          </a>
        </Grid>
        <Grid item xs={4} sm={4} md={4} style={{ textAlign: 'center' }}>
          <a href="https://www.instagram.com/navzdy_online?igsh=MXRrM3FpcTNlZG45NQ==" target="_blank" rel="noopener noreferrer" className="footer-link">
            <InstagramIcon fontSize="large" />
          </a>
        </Grid>
      </Grid>
       {/* Cookie upozornenie */}
       <CookieConsent
        location="bottom"
        buttonText="Súhlasím"
        declineButtonText="Nesúhlasím"
        enableDeclineButton
        buttonWrapperClasses="cookieButtons"
        cookieName="siteCookieConsent"
        style={{ background: "#35474E" }}
        buttonStyle={{ background: "#b3d5e7", color: "#000", fontSize: "14px" }}
        declineButtonStyle={{ background: "#fef7f0", color: "#000", fontSize: "14px" }}
      >
        Tento web používa cookies na zlepšenie vášho zážitku. Prečítajte si naše{" "}
        <a href="/cookies-policy" style={{ color: "#fff", textDecoration: "underline" }}>
          obchodné podmienky
        </a>.
      </CookieConsent>
  </div>

  );
}

export default Home;
